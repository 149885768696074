import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Grid,
  ListItem,
  ListItemText,
  TextField,
  Typography,
  Alert,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useContractService from '../../hooks/useContractService';
import styled from '@emotion/styled';
import useToast from '@tenant/utility/hooks/useToast';
import IntlMessages from '@tenant/utility/IntlMessages';
import { Formik } from 'formik';
import { numberToInteger } from '@tenant/utility/helper/Utils';
import * as yup from 'yup';

const StyledStack = styled(Stack)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.grey[400],
  borderRadius: theme.spacing(1),
  marginTop: theme.spacing(4),
}));

const validationSchema = yup.object({
  LockPoints: yup.number().required().integer().min(1).label('Lock Points'),
  Remaining: yup.number().required().integer().min(0).label('Lock Points'),
  Reason: yup.string().required(),
});

const LockPointsConfirm = ({
  visible,
  onClose,
  contractId,
  contract,
  onCallBackSubmitLockPoint,
}) => {
  const [loadingBtn, setLoadingBtn] = useState(false);
  const { lockPoint } = useContractService();
  const { showSuccessMessage, showErrorMessage } = useToast();

  const onSubmitLockPoint = useCallback(
    (data) => {
      setLoadingBtn(true);
      const { payload } = data ?? {};
      lockPoint({
        payload: payload,
        onSuccess: () => {
          showSuccessMessage('Lock point successfully');
          onClose?.();
          onCallBackSubmitLockPoint?.(contractId);
        },
        onError: (err) => {
          const message =
            typeof err?.response?.data?.Messages === 'string'
              ? err.response.data.Messages
              : 'Lock point failed!';
          showErrorMessage(message);
        },
        onFinally: () => {
          setLoadingBtn(false);
        },
      });
    },
    [lockPoint],
  );

  return (
    <Dialog
      open={visible}
      maxWidth='md'
      fullWidth
      height={500}
      onClose={onClose}
    >
      <Formik
        initialValues={{
          CurrentPoints: contract?.PointsBalance || 0,
          Remaining: contract?.PointsBalance || 0,
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          setLoadingBtn(true);
          onSubmitLockPoint({
            payload: {
              contractId,
              Points: values.LockPoints,
              LockReason: values.Reason,
            },
            onSuccess: () => {
              resetForm(initialValuesForm);
            },
            onFinally: () => {
              setLoadingBtn(false);
            },
          });
        }}
      >
        {({ submitForm, errors, values, validateField, setFieldValue }) => (
          <>
            <DialogTitle fontSize={16}>Lock Points</DialogTitle>
            <DialogContent mt={4}>
              <Box mb={4}>
                <Alert severity='info'>
                  <Typography component='i'>
                    Once submitted, this points{' '}
                    <Typography component={'span'} color={'error.main'}>
                      will be locked and unavailable{' '}
                    </Typography>
                    for use until unlock it. Please confirm that you wish to
                    proceed.
                  </Typography>
                </Alert>
              </Box>

              <Box mt={2}>
                <StyledStack direction='column'>
                  <ListItem
                    secondaryAction={
                      <Typography
                        sx={{
                          color: 'primary.main',
                        }}
                      >
                        {values.CurrentPoints ?? 0}
                      </Typography>
                    }
                  >
                    <ListItemText
                      primary={<IntlMessages id='productForm.balance' />}
                    />
                  </ListItem>
                  <ListItem
                    secondaryAction={
                      <Box
                        sx={{
                          color: 'warning.main',
                        }}
                      >
                        <TextField
                          type='number'
                          size='small'
                          disabled={
                            numberToInteger(contract?.PointsBalance) <= 0
                          }
                          InputProps={{
                            inputProps: {
                              required: true,
                              min: 1,
                              max: values.CurrentPoints ?? 0,
                            },
                          }}
                          value={values.LockPoints ?? 0}
                          error={!!errors.LockPoints}
                          helperText={errors.LockPoints}
                          placeholder=''
                          sx={{
                            minWidth: 300,
                          }}
                          onChange={(e) => {
                            const value = e.target.value ?? 0;
                            const newValue =
                              value < 0
                                ? 0
                                : value > values.CurrentPoints
                                ? values.CurrentPoints
                                : value;
                            setFieldValue('LockPoints', newValue, false);
                            setFieldValue(
                              'Remaining',
                              values.CurrentPoints - numberToInteger(newValue),
                              false,
                            );
                          }}
                          onBlur={(e) =>
                            setFieldValue(
                              'LockPoints',
                              numberToInteger(e.target.value),
                            )
                          }
                        />
                      </Box>
                    }
                  >
                    <ListItemText primary={<IntlMessages id='lockPoints' />} />
                  </ListItem>
                  <ListItem
                    secondaryAction={
                      <Typography
                        sx={{
                          color: 'success.main',
                          fontWeight: 600,
                        }}
                      >
                        {values.Remaining ?? 0}
                      </Typography>
                    }
                  >
                    <ListItemText
                      primary={<IntlMessages id='remainingPoints' />}
                      primaryTypographyProps={{
                        fontWeight: 500,
                      }}
                    />
                  </ListItem>
                </StyledStack>
              </Box>

              <Grid container spacing={4} mt={1}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    required
                    multiline
                    rows={4}
                    value={values.Reason ?? ''}
                    label='Reason'
                    variant='outlined'
                    error={!!errors.Reason}
                    helperText={errors.Reason}
                    onChange={(e) => setFieldValue('Reason', e.target.value)}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose}>Cancel</Button>
              <LoadingButton
                loading={loadingBtn}
                variant='contained'
                onClick={submitForm}
                disabled={numberToInteger(contract?.PointsBalance) <= 0}
              >
                Save
              </LoadingButton>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default React.memo(LockPointsConfirm);

LockPointsConfirm.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onCallBackSubmitLockPoint: PropTypes.func,
  contractId: PropTypes.string,
  contract: PropTypes.object,
};
