import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, Button, Menu, MenuItem, Tooltip } from '@mui/material';
import useModal from '@tenant/utility/hooks/useModal';
import DetailUpdateMaxNumberUsers from './DetailUpdateMaxNumberUsers';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useAuthUser } from '@tenant/utility/AuthHooks';
import { useNavigate, useParams } from 'react-router-dom';
import useCompany from '../../hooks/useCompany';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import clsx from 'clsx';
import PopUpConfirm from '../Tools/PopUpConfirm';
import {
  PAYMENT_STATUS_ENUM,
  CONTRACT_LENGTH_MONTH_NUMBER,
  TYPE_OF_CONFIRM,
} from '../../contants/enums';
import useToast from '@tenant/utility/hooks/useToast';
import { setMaxUserNumber } from 'redux/actions';
import LockoutContractConfirm from './LockoutContractConfirm';
import ReactiveContractConfirm from './ReactiveContractConfirm';
import ChangePaymentMethodConfirm from './ChangePaymentMethodConfirm';
import LockPointsConfirm from './LockPointsConfirm';

const END_SUBSCRIPTION_TOOLTIP = 'The contract is ended subscription.';
const INACTIVE_TOOLTIP = 'The contract is Inactive.';
const UNAVAILABLE_POINT_TOOLTIP = 'The points are empty or not available.';
const NOT_SUPPORT_MULTI_TENANTS_TOOLTIP =
  'Multiple-tenant contracts are not supported.';
const AUTO_CANCEL_CONTRACT_TOOLTIP =
  'The pending switch contract will automatically cancel upon expiration.';
const IS_PENDING_CHANGE_PAYMENT_METHOD_TOOLTIP =
  'This contract is currently in progress for the payment method change.';

const DetailMoreTools = ({
  isInactive,
  isEndSubscription,
  disabledReduce,
  onSelectedUpdateMaxNumberUser,
  maximumNumberOfUsers,
  contractId,
  onGetContractAndInvoice,
  tenantId,
  subscriptionStart,
  subscriptionEnd,
  tenantName,
  accountingManualPlan,
  paymentMethodType,
  status,
  contract,
  setContract,
  tenants = [],
  disabledCreateVehicleTracking,
  setTenantGroup,
  setLoading,
  isLoading,
  onGetContractDetail,
  onGetLockPointHistory,
  isUnavailablePoints,
  onCallBackSubmitLockPoint,
  isAnnual,
}) => {
  const { id } = useParams();
  const { visible, onShow, onClose } = useModal();
  const {
    visible: visibleLockout,
    onShow: onShowLockout,
    onClose: onCloseLockout,
  } = useModal();
  const {
    visible: visibleReactive,
    onShow: onShowReactive,
    onClose: onCloseReactive,
  } = useModal();
  const {
    visible: isChangePaymentMethodModalOpened,
    onShow: onShowChangePaymentMethodModal,
    onClose: onCloseChangePaymentMethodModal,
  } = useModal();
  const {
    visible: visibleLockPoints,
    onShow: onShowVisibleLockPoints,
    onClose: onCloseVisibleLockPoints,
  } = useModal();
  const dispatch = useDispatch();
  const [isMultipleTenants, setIsMultipleTenants] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isAdditionalUser, setIsAdditionalUser] = useState(false);
  const navigate = useNavigate();
  const { user } = useAuthUser();
  const [openPopUpConfirm, setOpenPopUpConfirm] = useState(false);
  const [typeConfirm, setTypeConfirm] = useState('');
  const [currentTenant, setCurrentTenant] = useState(null);
  const { showSuccessMessage, showErrorMessage } = useToast();

  const {
    checkMultiTenantCompany,
    generateDraftContract,
    activeDraftContract,
  } = useCompany();

  const isEmailVerified = useMemo(() => {
    return [
      'cuongn@joblogic.com',
      'harrisj@joblogic.com',
      'baol@joblogic.com',
      'hashirh@joblogic.com',
      'sallym@joblogic.com',
      'lynd@joblogic.com',
      'samanc@joblogic.com',
    ].includes(user?.username?.toLocaleLowerCase());
  }, [user?.username]);

  const isActiveContract = useMemo(() => {
    return +status === PAYMENT_STATUS_ENUM.Active;
  }, [status]);

  const isDraftContract = useMemo(() => {
    return +status === PAYMENT_STATUS_ENUM.Draft;
  }, [status]);

  const isEditContract = useMemo(() => {
    return isEmailVerified || isDraftContract;
  }, [isEmailVerified, isDraftContract]);

  const isReactiveContract = useMemo(() => {
    return [
      PAYMENT_STATUS_ENUM['Locked Out'],
      PAYMENT_STATUS_ENUM['Pending Cancel'],
    ].includes(+status);
  }, [status]);

  const increaseExtra = useMemo(() =>
    isEndSubscription
      ? END_SUBSCRIPTION_TOOLTIP
      : isInactive
      ? INACTIVE_TOOLTIP
      : null,
  );

  const isMonthlyContract =
    contract?.ContractLength?.toString() === CONTRACT_LENGTH_MONTH_NUMBER;

  const isPendingChangePaymentMethod = !!contract?.IsPendingChangePaymentMethod;

  const cancelContractTooltip = isPendingChangePaymentMethod
    ? AUTO_CANCEL_CONTRACT_TOOLTIP
    : null;

  const changePaymentMethodTooltip = isMultipleTenants
    ? NOT_SUPPORT_MULTI_TENANTS_TOOLTIP
    : isPendingChangePaymentMethod
    ? IS_PENDING_CHANGE_PAYMENT_METHOD_TOOLTIP
    : null;

  const reduceExtra = useMemo(() =>
    isEndSubscription
      ? END_SUBSCRIPTION_TOOLTIP
      : isInactive
      ? INACTIVE_TOOLTIP
      : null,
  );
  const lockPointTooltip = useMemo(() =>
    isInactive
      ? INACTIVE_TOOLTIP
      : isUnavailablePoints
      ? UNAVAILABLE_POINT_TOOLTIP
      : null,
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenPopUpConfirm = (type) => {
    setOpenPopUpConfirm(true);
    setTypeConfirm(type);
  };

  const handleGenerateDraftContract = () => {
    generateDraftContract(
      contractId,
      () => {
        navigate(`/company/${id}`);
        handleClose();
      },
      (message) => {
        handleClose();
        showErrorMessage(message || 'Generate draft contract failed');
      },
    );
  };

  const handleActiveDraftContract = async () => {
    setLoading(true);
    activeDraftContract(
      contractId,
      (data) => {
        if (data) {
          showSuccessMessage('Active draft contract successfully');
          setTimeout(() => window.location.reload(), 500);
        }
      },
      (message) => {
        showErrorMessage(message || 'Active draft contract failed');
        setLoading(false);
      },
    );
  };

  const handleChangePaymentMethod = () => {
    isMonthlyContract
      ? navigate(
          `/company/add?tenantIds=${tenantId}&companyId=${id}&contractId=${contractId}`,
        )
      : onShowChangePaymentMethodModal();
    handleClose();
  };

  useEffect(() => {
    if (id)
      checkMultiTenantCompany(id).then((response) => {
        const { IsMultiTenant } = response;
        setIsMultipleTenants(!!IsMultiTenant);
      });
  }, [id]);

  useEffect(() => {
    if (contract?.Contacts[0] && tenants?.length > 0) {
      tenants.forEach((Tenant) => {
        if (Tenant.TenantId === contract.Contacts[0].TenantId) {
          dispatch(setMaxUserNumber(Tenant.MaximumNumberUser));
          setCurrentTenant({ ...contract.Contacts[0] });
        }
      });
    }
  }, [tenants]);

  const handleCreateVehicleTrackingRequest = () => {
    navigate(`/vehicle-tracking/add?tenantId=${tenantId}`);
  };

  return (
    <>
      {currentTenant && (
        <DetailUpdateMaxNumberUsers
          tenantName={tenantName}
          tenantId={currentTenant?.TenantId}
          isAdditionalUser={isAdditionalUser}
          contractId={contractId}
          visible={visible}
          onClose={onClose}
          onShow={onShow}
          maxNumberOfUsers={maximumNumberOfUsers}
          onGetContractAndInvoice={onGetContractAndInvoice}
          subscriptionStart={subscriptionStart}
          subscriptionEnd={subscriptionEnd}
          accountingManualPlan={accountingManualPlan}
          paymentMethodType={paymentMethodType}
          tenants={tenants}
          currentTenant={currentTenant}
          setCurrentTenant={setCurrentTenant}
          setTenantGroup={setTenantGroup}
          isDisabledReduce={disabledReduce}
        />
      )}

      {visibleLockout && (
        <LockoutContractConfirm
          contractId={contractId}
          visible={visibleLockout}
          onClose={onCloseLockout}
          onGetContractDetail={onGetContractDetail}
          tenants={tenants}
        />
      )}

      {visibleLockPoints && (
        <LockPointsConfirm
          contractId={contractId}
          visible={visibleLockPoints}
          onClose={onCloseVisibleLockPoints}
          onCallBackSubmitLockPoint={onCallBackSubmitLockPoint}
          onGetLockPointHistory={onGetLockPointHistory}
          contract={contract}
        />
      )}

      {visibleReactive && (
        <ReactiveContractConfirm
          contractId={contractId}
          visible={visibleReactive}
          onClose={onCloseReactive}
          onCallBackSubmit={onGetContractAndInvoice}
          tenants={tenants}
        />
      )}

      <ChangePaymentMethodConfirm
        visible={isChangePaymentMethodModalOpened}
        onClose={onCloseChangePaymentMethodModal}
        onSuccess={() => onGetContractDetail(contractId)}
        contractId={contractId}
        contract={contract}
      />

      <Button
        variant='outlined'
        endIcon={<KeyboardArrowDownIcon fontSize='inherit' />}
        onClick={handleClick}
      >
        Actions
      </Button>

      <Menu
        id='company-more-tool-menu'
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {!isDraftContract && (
          <MenuItem
            className={clsx({
              'Mui-disabled': !!increaseExtra,
            })}
            sx={{ justifyContent: 'space-between' }}
            onClick={() => {
              if (!!increaseExtra) {
                return;
              }
              onSelectedUpdateMaxNumberUser?.();
              onShow();
              handleClose();
              setIsAdditionalUser(true);
            }}
          >
            <Box component='span' mr={2}>
              Increase Max Users
            </Box>

            {!!increaseExtra && (
              <Tooltip
                title={increaseExtra}
                sx={{ fontSize: 18, pointerEvents: 'auto' }}
              >
                <HelpOutlineIcon fontSize={'inherit'} />
              </Tooltip>
            )}
          </MenuItem>
        )}

        {!isDraftContract && (
          <MenuItem
            className={clsx({
              'Mui-disabled': !!reduceExtra,
            })}
            sx={{ justifyContent: 'space-between' }}
            onClick={() => {
              if (!!reduceExtra) {
                return;
              }
              onSelectedUpdateMaxNumberUser?.();
              onShow();
              handleClose();
              setIsAdditionalUser(false);
            }}
          >
            <Box component='span' mr={2}>
              Reduce Max Users
            </Box>

            {!!reduceExtra && (
              <Tooltip
                title={reduceExtra}
                sx={{ fontSize: 18, pointerEvents: 'auto' }}
              >
                <HelpOutlineIcon fontSize={'inherit'} />
              </Tooltip>
            )}
          </MenuItem>
        )}

        {isReactiveContract && (
          <MenuItem
            sx={{ justifyContent: 'space-between' }}
            onClick={() => {
              onShowReactive();
              handleClose();
            }}
          >
            <Box component='span' mr={2}>
              Reactivate Tenant
            </Box>
          </MenuItem>
        )}

        {isEditContract && (
          <>
            <MenuItem
              onClick={() => {
                navigate(`/company/${id}/contract/${contractId}/edit`);
              }}
              disabled={isLoading}
            >
              {+status === PAYMENT_STATUS_ENUM.Draft
                ? 'Edit Draft Contract'
                : 'Edit Contract'}
            </MenuItem>
            {+status === PAYMENT_STATUS_ENUM.Draft && (
              <MenuItem
                onClick={handleActiveDraftContract}
                disabled={isLoading}
              >
                Active Draft Contract
              </MenuItem>
            )}
          </>
        )}
        {+status === PAYMENT_STATUS_ENUM.Active && (
          <MenuItem
            disabled={!!cancelContractTooltip}
            onClick={() => {
              if (cancelContractTooltip) return;
              handleOpenPopUpConfirm(TYPE_OF_CONFIRM.CANCEL);
            }}
          >
            <Box component='span' mr={2}>
              Cancel Contract
            </Box>

            {cancelContractTooltip && (
              <Tooltip
                title={cancelContractTooltip}
                sx={{ fontSize: 18, pointerEvents: 'auto' }}
              >
                <HelpOutlineIcon fontSize={'inherit'} />
              </Tooltip>
            )}
          </MenuItem>
        )}
        {isActiveContract && (
          <MenuItem
            sx={{ justifyContent: 'space-between' }}
            disabled={disabledCreateVehicleTracking !== ''}
            onClick={handleCreateVehicleTrackingRequest}
          >
            <Box component='span' mr={2}>
              Create vehicle tracking request
            </Box>

            {disabledCreateVehicleTracking !== '' && (
              <Tooltip
                title={disabledCreateVehicleTracking}
                sx={{ fontSize: 18, pointerEvents: 'auto' }}
              >
                <HelpOutlineIcon fontSize={'inherit'} />
              </Tooltip>
            )}
          </MenuItem>
        )}
        {+status === 1 &&
          !isMonthlyContract &&
          !isPendingChangePaymentMethod && (
            <MenuItem onClick={handleGenerateDraftContract}>
              Generate Draft Contract
            </MenuItem>
          )}
        {isActiveContract && !isPendingChangePaymentMethod && (
          <MenuItem
            onClick={() => {
              onShowLockout();
              handleClose();
            }}
          >
            Lockout Contract
          </MenuItem>
        )}
        {isActiveContract && (
          <MenuItem
            disabled={!!changePaymentMethodTooltip}
            onClick={() => {
              if (changePaymentMethodTooltip) return;
              handleChangePaymentMethod();
            }}
          >
            <Box component='span' mr={2}>
              Change Payment Method To{' '}
              {isMonthlyContract ? 'Annually' : 'Monthly'}
            </Box>
            {!!changePaymentMethodTooltip && (
              <Tooltip
                title={changePaymentMethodTooltip}
                sx={{ fontSize: 18, pointerEvents: 'auto' }}
              >
                <HelpOutlineIcon fontSize={'inherit'} />
              </Tooltip>
            )}
          </MenuItem>
        )}
        {isActiveContract && isAnnual && (
          <MenuItem
            disabled={!!lockPointTooltip}
            onClick={() => {
              if (lockPointTooltip) {
                return;
              }
              onShowVisibleLockPoints();
              handleClose();
            }}
          >
            <Box component='span' mr={2}>
              Lock Points
            </Box>
            {!!lockPointTooltip && (
              <Tooltip
                title={lockPointTooltip}
                sx={{ fontSize: 18, pointerEvents: 'auto' }}
              >
                <HelpOutlineIcon fontSize={'inherit'} />
              </Tooltip>
            )}
          </MenuItem>
        )}
      </Menu>

      <PopUpConfirm
        open={openPopUpConfirm}
        handleClose={() => {
          setOpenPopUpConfirm(false);
          handleClose();
        }}
        typeConfirm={typeConfirm}
        contract={contract}
        setContract={setContract}
      />
    </>
  );
};

export default React.memo(DetailMoreTools);

DetailMoreTools.propTypes = {
  onSelectedUpdateMaxNumberUser: PropTypes.func,
  maximumNumberOfUsers: PropTypes.number,
  contractId: PropTypes.string,
  onGetContractAndInvoice: PropTypes.func,
  onGetContractDetail: PropTypes.func,
  onGetLockPointHistory: PropTypes.func,
  tenantId: PropTypes.string,
  subscriptionStart: PropTypes.string,
  subscriptionEnd: PropTypes.string,
  tenantName: PropTypes.string,
  accountingManualPlan: PropTypes.bool,
  disabledReduce: PropTypes.bool,
  isEndSubscription: PropTypes.bool,
  isInactive: PropTypes.bool,
  paymentMethodType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  status: PropTypes.number,
  contract: PropTypes.object,
  tenants: PropTypes.array,
  setTenantGroup: PropTypes.func,
  setLoading: PropTypes.func,
  isLoading: PropTypes.bool,
  isUnavailablePoints: PropTypes.bool,
  onCallBackSubmitLockPoint: PropTypes.func,
  isAnnual: PropTypes.bool,
};
